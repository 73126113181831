import React from 'react';
import Action from '../components/action/action';
import Banner from '../components/banner/banner';
import Hero from '../components/Hero/Hero';
import HIW from '../components/hiw/Hiw';
import Info from '../components/Info/Info';
import LeftInfo from '../components/Info/LeftInfo/LeftInfo';
import Navbar from '../components/Navbar/Navbar';
import Quotes from '../components/quotes/quotes';
import RightInfo from '../components/Info/RightInfo/RightInfo';
import Intro from '../components/Intro/intro';
import Footer from '../components/Footer/Footer';


const Homepage = () => {
  return (
    <div className='home'>
        <Navbar />
        <Hero />
        <Info 
          topline={"Powerful WIFI and Storage Wherever You Go"}
          imageName={require("../assets/astro.png")}
          header={"STORE ONLINE GAMES, PHOTOS, VIDEOS, APPS & MORE"}
          paragraph={"With Etheware, users can engage in activities that historically have not been possible with satellite internet, all from an app."}
          buttontext={"View the Trailer"}
          linkTo={"https://www.instagram.com/ethewaretech/"}
        />
        <RightInfo 
          title={"ACCESS YOUR CLOUD + INTERNET ON ANY DEVICE"}
          imageName={require("../assets/preview.png")}
          paragraph={"Etheware Space offers powerful satellite network connections and total control over your Cloud Storage no matter where you go. "}
        />
        
       
       
       
        <LeftInfo 
          title={"SHARE INTERNET CONNECTION FROM MILES AWAY"}
          imageName={require("../assets/empt.png")}
          imgAlt={"Etheware Space Mobile View"}
          body={"Share your satellite internet and cloud storage with people in your Group, get updates, message and talk privately and view real-time performance metrics such as download speed, latency, and uptime."}
          paragraph={"Anyone with your space account on their device can use your network or cloud anywhere, anytime with your permission."}
        />
        <Info
          topline={"SERVICE AVAILABLE IN 195 COUNTRIES"}
          imageName={require("../assets/astro2.png")}
          header={"EASY SELF INSTALL"}
          subtitle={"No need for  WiFi routers, cables or bases. All you need is the Etheware Space app downloaded on your desktop, mobile phone or tablet to begin connecting and storing."}
          paragraph={"The Etheware Space apps allow you to upload and backup files to your cloud, access 250+ Apps and Games on the Etheware App Store, change settings, and more. "}
          buttontext={"Learn More"}
          linkTo={"/technology"}
        />
        <Banner
          bannerImg={require("../assets/stars.png")}
          title={"TRAVEL WITH ETHEWARE SPACE"}
          subtitle={"Etheware Space comes with everything you need to get your personal cloud with satellite internet up and running in seconds, no matter where you are."}
          buttonText={"Sign Up to Beta Test"}
          linkTo={"/beta-test"}
        />
        <Quotes />
       
        
        <Action />
        <Footer />
    </div>
  )
}

export default Homepage