import React from 'react';
import './index.css';

const Banner = ({ bannerImg, title, subtitle, subtitle2, buttonText, linkTo }) => {
  return (
    <div className='banner__container'>
        <div className='banner'>
                <img src={bannerImg} alt="" id='home'/>
        
        </div>
        <div className='banner__content'>
            <h1>{title}</h1>
            <p>{subtitle}</p>
            <h2>{subtitle2}</h2>
            <a href={linkTo}><button className='banner__btn'>{buttonText}</button></a>
        </div>
    </div>
  )
}

export default Banner