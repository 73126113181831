import React from 'react'
import Banner from '../components/banner/banner'
import CTA from '../components/cta/CTA'
import Footer from '../components/Footer/Footer'
import Info from '../components/Info/Info'
import LeftInfo from '../components/Info/LeftInfo/LeftInfo'
import RightInfo from '../components/Info/RightInfo/RightInfo'
import Navbar from '../components/Navbar/Navbar'

const SupportedLocationsPage = () => {
  return (
    <div className='home'>
        <Navbar />
        <Banner
          bannerImg={require("../assets/earth.png")}
          title={"FAST, SECURE SPEEDS ANYWHERE YOU GO"}
          subtitle={"Etheware Space provides satellite internet and cloud storage available anywhere in the world, and regardless of location through the app."}
          buttonText={"Pre-Order"}
          linkTo={"/beta-test"}
        />
        <Info 
          topline={"STAY CONNECTED ANYWHERE IN THE WORLD"}
          imageName={require("../assets/6.png")}
          paragraph={"Etheware Space is ideal for rural and remote areas. Add as many capabilities as you need to your Etheware account and control them all from a single synchronized account."}
          buttontext={"Learn More"}
          linkTo={"https://www.etheware.com"}
        /> 
        <CTA />
        <Footer />
    </div>
  )
}

export default SupportedLocationsPage