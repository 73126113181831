import React from 'react';
import Banner from '../../components/banner/banner';
import CTA from '../../components/cta/CTA';
import Footer from '../../components/Footer/Footer';
import LeftInfo from '../../components/Info/LeftInfo/LeftInfo';
import Intro from '../../components/Intro/intro';
import Navbar from '../../components/Navbar/Navbar';
import ComparisonTable from '../../containers/comparison/ComparisonTable';
import ExtendedComparison from '../../containers/comparison/ExtenededComparison';


const StoragePage = () => {
  return (
    <div className='storage'>
        <Navbar />
        <Banner
          bannerImg={require("../../assets/4.png")}
          title={"STORE WITH ETHEWARE SPACE"}
          subtitle={"Etheware Space keeps all of your valuables - images, files, notes, and more — safe, up to date, and accessible wherever you are."}
          buttonText={"Learn More"}
          linkTo={"https://www.etheware.com"}
        />
        <Banner
          bannerImg={require("../../assets/3.png")}
          title={"BACKUP AND SYNC ALL YOUR DEVICES INTO ONE ACCOUNT"}
          subtitle={"Backup and Sync all your computers into one account. With this you'll get an even more an powerful and connected experience as all your important info will be synced across your devices."}
          buttonText={"Test it Out"}
          linkTo={"/beta-test"}
        />
        <CTA />
        <Banner
          bannerImg={require("../../assets/astro.png")}
          title={"TRAVEL WITH ETHEWARE SPACE"}
          subtitle={"Etheware Space comes with everything you need to get in your personal cloud and online in seconds"}
          buttonText={"Try the Space Demo"}
          linkTo={"/beta-test"}
        />
        
        <Footer />
    </div>
  )
}

export default StoragePage

/* 
<ComparisonTable />

*/