import React from 'react';
import './index.css';

const Info = ({ topline, header, subheader, subtitle, paragraph, buttontext, imageName, linkTo}) => {
  return (
    <div className='info'>
        <div className='info__container'>
            <div className="info__text">
                <h3>{topline}</h3>
                <h1>{header}</h1>
                <h4>{subtitle}</h4>
                <h2>{subheader} </h2>
               
                <p>{paragraph}</p>
                <div className='info__button'>
                    <a href={linkTo}><button className='gradient__button'>{buttontext}</button></a>
                </div>
            </div>
            <div className='info__image'>
                <img src={imageName} alt="Streaming, Video Calls, Online Gaming & More with Etheware Space" />
            </div>
            
        </div>
    </div>
  )
}

export default Info