import React from 'react';
import './index.css';
import AppImage from '../../assets/preview.png';

const Action = () => {
  return (
    <div>
        <div className='action__container'>
            <div className='action__image'>
                <img src={AppImage} alt="Download Etheware Space" />
            </div>
            <div className='action__text'>
                <h1>MANAGE ETHEWARE SPACE ON ANY DEVICE</h1>
                <p>The Etheware Space apps allow you to upload and backup 
                files to your cloud, access the Etheware App Store for 250+ Apps and Games, 
                change settings, get updates, contact support, and view real-time performance 
                metrics such as download speed, latency, and uptime.</p>
                <h2>Download your space app on any device for use anywhere, anytime.</h2>
                <div className='action__btns'>
                <a href='/beta-test'><button>Sign Up to Beta Test</button></a>
                <a href='/technology'><button>Learn More about Space</button></a>
            </div>
            </div>
            
        </div>
    </div>
  )
}

export default Action