import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Homepage from './pages/Homepage';
import Technology from './pages/Technology';
import StoragePage from './pages/StoragePage/StoragePage';
import SatellitePage from './pages/SatellitePage/SatellitePage';
import SupportedLocationsPage from './pages/SupportedLocationsPage';
import PreorderPage from './pages/PreorderPage';


function App() {
  return (
    <Router>
    <Routes>
      <Route path="/" element={<Homepage />} />
      <Route path="/storage" element={<StoragePage />} />
      <Route path="/satellite-internet" element={<SatellitePage />} />
      <Route path="/technology" element={<Technology />} />
      <Route path="/supported-locations" element={<SupportedLocationsPage />} />
      <Route path="/beta-test" element={<PreorderPage />} />
    </Routes>
  </Router>
  );
}

export default App;
