import React from 'react';
import './index.css';
import AppImage from '../../../assets/preview.png';

const LeftInfo = ({ title, body, paragraph, imageName, imgAlt }) => {
  return (
    <div className='info'>
        <div className='left__container'>
           
            <div className='left__text'>
                <h1>{title}</h1>
                <p>{body}</p>
                <h2>{paragraph}</h2>
            </div>
            <div className='info__image'>
                <img src={imageName} alt={imgAlt} />
            </div>
            
        </div>
    </div>
  )
}

export default LeftInfo