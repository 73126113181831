import React from 'react';
import { Link } from 'react-router-dom';
import './index.css';

const CTA = () => (
  <div className="cta">
    <div className="cta-content">
      <p>Want to get more involved?</p>
      <h3>Follow us on Instagram</h3>
    </div>
    <div className="cta-btn">
      <button type="button"><p><Link to="https://www.instagram.com/ethewaretech">Etheware's IG</Link></p></button>
    </div>
  </div>
);

export default CTA;