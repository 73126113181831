

import React, { useState } from "react";
import'./index.css';
import Logo from '../../assets/logo.png'
import Icon from '../../assets/icon.png'
import Anima from './../../assets/anima.png';
import { RiMenuFill, RiCloseLine } from 'react-icons/ri';
import { Image } from "react-bootstrap";


const Navbar = () => {

    const [toggleMenu, setToggleMenu] = useState(false);
    const [accountMenu, setAccountMenu] = useState(false);

    const Menu = () => (
        <nav>
        <RiCloseLine color="#fff" size={27} onClick={() => setToggleMenu(false)}/>    
        <p><a href="/storage">Storage</a></p>
          <p><a href="/satellite-internet">Satellite Internet</a></p>
          <p><a href="/technology">Technology</a></p>
          <p><a href="/supported-locations">Supported Locations</a></p>
          <p><a href="https://www.etheware.com/contact">Support</a></p>
          <p><a href="/beta-test"><p>Beta Test Now</p></a></p>
        </nav>
    ) 



  return (
    <div className='navbar'>
        <div className="navbar__logo">
            <a href="/">
                <img src={Logo} alt="Etheware Space 2022" loading="lazy"/>
                </a>
            </div>
            <div className="navbar__icon">
                <a href="/">
                <img src={Icon} alt="Etheware Space 2022" loading="lazy"/>
                </a>
            </div>
            <div className="icon__div"/>
            <div className="navbar__links">  
                <div className="navbar__links-container">
                    <div className="nav__container">
                    <p><a href="/Storage">Storage</a></p>
                    <div className="icon__div"/>
                    <p><a href="/satellite-internet">Satellite Internet</a></p>
                    <div className="icon__div"/>
                    <p><a href="/technology">Technology</a></p>
                    <div className="icon__div"/>
                    <p><a href="/supported-locations">Supported Locations</a></p>
                    <p><a href="https://www.etheware.com/contact">Support</a></p>
                </div>
            </div>
            <div className="navbar__menu">
                {toggleMenu
                ? <RiCloseLine color="transparent" size={27} onClick={() => setToggleMenu(false)}/>
                : <RiMenuFill color="#fff" size={27} onClick={() => setToggleMenu(true)}/>
                }
                {toggleMenu && (
                <div className="nav-menu__container scale-up-center">
                    <div className="menu__links">
                        <Menu />
                    </div>
                </div>
                )}
            </div>
            <div className="icon__div"/>
        </div>
        
    </div>
  )
}

export default Navbar