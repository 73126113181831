import React from 'react';

const RightInfo = ({ title, body, paragraph, imageName }) => {
  return (
    <div className='info'>
        <div className='left__container'>
            <div className='info__image'>
                <img src={imageName} alt="Download Etheware Space" />
            </div>
            <div className='left__text'>
                <h1>{title}</h1>
                <p>{body}</p>
                <h2>{paragraph}</h2>
            </div>
            
            
        </div>
    </div>
  )
}

export default RightInfo