import React from 'react'
import Banner from '../../components/banner/banner'
import CTA from '../../components/cta/CTA'
import Footer from '../../components/Footer/Footer'
import Navbar from '../../components/Navbar/Navbar'
import ExtendedComparison from '../../containers/comparison/ExtenededComparison'
import SatComparisonTable from '../../containers/comparison/SatComparisonTable'

const SatellitePage = () => {
  return (
    <div className='home'>
      <Navbar />
      <Banner
        bannerImg={require("../../assets/astro.png")}
        title={"CREATE YOUR OWN SHAREABLE NETWORK OR SERVER IN SECONDS"}
        subtitle={"Etheware Space will have you creating your very own private satellite internet connection or server in seconds. This can be shared with anyone who has the Etheware Space app downloaded on their device."}
        buttonText={"Try It Out"}
        linkTo={"/beta-test"}
      />
      
      
      <Banner
        bannerImg={require("../../assets/3.png")}
        title={"HIGHER SPEED, LOW LATENCY"}
        subtitle={"Users can expect download speeds of up to 450 Mbps and latency of 10-50ms, allowing for high throughput connectivity for offices with up to 30 users, marketplaces, and demanding workloads worldwide."}
        subtitle2={" There are no long-term contracts or exclusivity obligations with Etheware Space. Just connect."}
        buttonText={"Sign Up to Test"}
        linkTo={"/beta-test"}
      />
      <Banner
        bannerImg={require("../../assets/5.png")}
        title={"ETHEWARE SPACE FOR SATELLITE INTERNET"}
        subtitle={"Etheware Space is developing a low latency, broadband internet system to meet the needs of consumers across the globe. "}
        buttonText={"Sign Up"}
        linkTo={"/beta-test"}
      />
      
      <CTA />
      
      
      <Footer />
    </div>
  )
}

export default SatellitePage

/* 
<SatComparisonTable />
<ExtendedComparison />
*/