import React from 'react'
import Action from '../components/action/action'
import Banner from '../components/banner/banner'
import Footer from '../components/Footer/Footer'
import Hero from '../components/Hero/Hero'
import HIW from '../components/hiw/Hiw'
import Info from '../components/Info/Info'
import LeftInfo from '../components/Info/LeftInfo/LeftInfo'
import RightInfo from '../components/Info/RightInfo/RightInfo'
import Intro from '../components/Intro/intro'
import Navbar from '../components/Navbar/Navbar'
import Quotes from '../components/quotes/quotes'

const Technology = () => {
  return (
    <div className='home'>
        <Navbar />  
        <Info
          imageName={require("../assets/astro2.png")}
          header={"ENGINEERED BY ETHEWARE"}
          paragraph={"Etheware is deploying the world's most sophisticated broadband internet infrastructure, combining its experience in application development, cloud computing, and networking. "}
          buttontext={"Learn More"}
        />
        <HIW />
        
        <Banner
          bannerImg={require("../assets/stars.png")}
          title={"THE MOST ADVANCED BROADBAND SATELLITE INTERNET AND CLOUD IN THE WORLD"}
          subtitle={"Etheware has extensive expertise in both space and cloud operations as the world's largest upcoming startup supplying satellite services - and one of the only providers in the world with the capacity to launch rockets on demand."}
          buttonText={"Visit Our Site"}
          linkTo={"https://www.etheware.com"}
        />
        <Footer />
    </div>
  )
}

export default Technology