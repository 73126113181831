import React from 'react';
import Footer from '../components/Footer/Footer';
import Navbar from '../components/Navbar/Navbar';
import SpacePreorder from '../components/SpacePreorder/SpacePreorder';

const PreorderPage = () => {
  return (
    <div>
        <Navbar />
        <SpacePreorder />
        <Footer />
    </div>
  )
}

export default PreorderPage