import React from 'react';
import './index.css';
import How from '../../assets/howitworks.png';

const HIW = () => {
  return (
    <div className='hiw'>
        <div className='hiw__text'>
            <h1>How Etheware Space Works</h1>
            <p>Most satellite internet services are provided by individual geostationary 
                satellites that circle the Earth at 35,786 kilometers. 
                As a result, the round trip transmission time between the user and the 
                satellite—also known as latency—is significant, making streaming, online gaming, 
                video conferencing, and other high data rate activities almost impossible.
            </p>
            <p>
                Etheware Space beats all the other competition with it's constellation of thousands of satellites
                 that orbit a significantly closer 
                distance to Earth, circling at 550 kilometers, and covering 
                the entire globe. Due to the low orbit, with Etheware
                satellites, latency is substantially lower—around 10 ms vs
                600+ ms.
            </p>
        </div>
        <div className='hiw__image'>
            <img src={How} alt="How Etheware Space Works" />
        </div>
    </div>
  )
}

export default HIW