import React, { useRef, useEffect } from 'react';
import Astronaut from '../../assets/icon.png';
import './index.css';
import emailjs from '@emailjs/browser';
import{ init } from '@emailjs/browser';

init("user_Nv6WEeTowVFjmAASEGfpj");

function SpacePreorder() {
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm("service_mraleeg","template_ic8nrgo", form.current, "user_Nv6WEeTowVFjmAASEGfpj") 
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log('Failed, refresh your page and try again...', error);
          });
          e.target.reset();
          alert('We have received your application to Beta-Test Etheware Space, you will receive an email with further instructions if you are accepted into the program. - Thank you, Etheware');
      };

    return ( 
        <div className="preorder">
            <div className="display__title">
                <h1 className="gradient__text2">
                    Sign up to Beta Test Etheware Space
                </h1>
                <p>Have access to the future before anyone else</p>
                <h2 className='super__title'>Test and share your opinion of the pre-released version of Etheware Space before it's official release</h2>
                <div className="header-content__input">
                    <form className="preorder-form" id="email-form" ref={form} onSubmit={sendEmail}>
                        <input type="name" 
                            name="full__name" 
                            placeholder="Full Name" 
                            required
                        />
                        <input type="email" 
                            name="email" 
                            placeholder="Email Address" 
                            required
                        />
                        <input type="tel" 
                            name="phone" 
                            placeholder="Phone Number" 
                            required
                        />
                        <input type="address" 
                            name="address" 
                            placeholder="Service Address" 
                            required
                        />
                        <button className='wl-su_btn' type="submit" value="Send">Get Started</button>
                    </form>
                    <p className="small__text">Testers will receive an Etheware verified certificate, a Limited Edition 2050 Etheware Beta-Tester Merchandise Gift, and have their name on the Etheware Space Credits page</p>
                    <p className='small__text'>Browse the internet and store like it's nobody's business. Because it isn't. Good for storing all the basics, like contacts, calendars, and notes or connecting to your own private internet anywhere you go.</p>
                </div>
            
            </div>
            <div className="header__image">
                <img src={Astronaut} alt='Planet Etheware' loading="lazy"/>
            </div>
        </div>
        
       
    )
}

export default SpacePreorder;
