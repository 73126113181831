import React from 'react';
import './index.css';
import Video from '../../assets/video.mp4';
import { render } from "react-dom";
import { motion } from "framer-motion";
import {Link, Routes, Route, useNavigate} from 'react-router-dom';


function Hero({ topline, title, subtitle, titleTwo, body, paragraph }) {
    const navigate = useNavigate();

    const handleSubmit = event => {
      event.preventDefault();
    
  
      // 👇️ redirect to /contacts
      navigate('/beta-test');
    };

    return (
        <div className='hero__container'> 
            <div className='hero'>
                <video className='videoTag' autoPlay loop muted>
                    <source src={Video} type='video/mp4' id='home'/>
                </video>
            </div>
            <div className='hero__content'>
                <h3>Powerful Satellite Internet meets Cloud Storage</h3>
                <h1>BETA TEST ETHEWARE SPACE</h1>
          
                <p>High-speed, low-latency broadband internet and cloud storage in remote and rural locations across the globe from an app. Testing now open, with limited spots available</p>
                <h2>The Future is Here.</h2>
                <h4>Sign up to try it Out, Upon Release</h4>
                <form className="header-form" id="email-form" >
                    <button onClick={handleSubmit} className='wl-su_btn' type="submit" value="Send" required>Reserve Your Slot</button>
                </form>
            </div>
        </div>
    )
}

export default Hero