import React from 'react';
import './index.css';

const Quotes = () => {
  return (
    <div className='quotes'>
    <h1>CUSTOMER QUOTES</h1>
        <div className='quotes__text'>
            
            <div className='quote__container'>
                <h1>"It's a completely different universe now because of Etheware Space. 
                    Before Etheware Space, we had to limit data and couldn't watch, save, or 
                    download 4K games and movies to use whenever we wanted. Now we have files uploading, 
                    Pandora playing, and Zoom running with no latency."
                </h1>
                <p>- Eric N. from McAfee | Oregon, USA</p>
            </div>
            <div className='quote__container'>
                <h1>"We are happy to report that we are no longer one of the
                 millions of people who are not online. Etheware Space brings a six-year
                journey to a swift finish by getting online in a matter of minutes."
                </h1>
                <p>- Alex S. from New York, USA</p>
            </div>
            <div className='quote__container'>
                <h1>"Total game changer!" There's 
                no overpromising and underdelivering like our 
                previous sluggish provider. Simply download the app, create an account 
                and you're done! We went from horrible connection to having the fastest 
                internet connection and biggest cloud storage 
                in the neighborhood in less than 5 minutes. "</h1>
                <p>- Allen B. from BAE Systems | Dubai, UAE</p>
            </div>
        </div>
    </div>
  )
}

export default Quotes