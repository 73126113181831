import React from 'react';
import './index.css';

const Footer = () => {
  return (
    <div className='footer'>
        <div className="footer-links">
                <div className="footer-links_div">
                    <h4>How It Works</h4>
                    <p><a href="/technology">Learn More About The Technology</a></p> 
                   
                </div>
                <div className="footer-links_div">
                    <h4>Why Etheware</h4>
                    <p><a href="/https://www.etheware.com/why-choose-etheware">Why Choose Etheware</a></p>
                    <p><a href="/https://www.etheware.com/etheware-difference">The Etheware Difference</a></p>
                    <p><a href="/https://www.etheware.com/company">Company</a></p>
                </div>
                <div className="footer-links_div">
                <h4>Manage Your Etheware Account</h4>
                    <p><a href="/https://www.etheware.com/sign-up">Create an Account</a></p>
                    <p><a href="/https://www.etheware.com/sign-in">Sign In</a></p>
                    <p><a href="/https://www.etheware.com/profile-settings">Manage Your Account</a></p>
                    
                   
                </div>
                
                <div className="footer-links_div">
                    <h4>Etheware.com</h4>
                    <p><a href="/https://www.etheware.com//store">Store</a></p>
                    <p><a href="/https://www.etheware.com//support">Etheware Support</a></p>
                    <p><a href="/https://www.etheware.com//trade-in">Etheware Trade-In</a></p>
                </div>

                <div className="footer-links_div">
                    <h4>About Etheware</h4>
                    <p><a href={"https://www.linkedin.com/company/etheware/"}>Career Opportunities</a></p>
                    <p><a href={"/https://www.etheware.com/contact"}>Contact Etheware</a></p>
                </div>

                <div className="footer-links_div">
                <h4>Get In Touch</h4>
                <p>Contact us at 1-858-255-4008</p>
                <p><a href={"mailto:solutions@etheware.com"}>or Email Etheware</a></p>
                <h6>United States</h6>
                </div>
            </div>
            <div className="footer-copyright">
                <p>Made by the 2022 Etheware.com team.</p>
            </div>
            <div className="footer__buttons">
                <p><a href="/https://www.etheware.com//legal">Privacy Policy</a></p>
                <div className="icon__div"/>
                <p><a href="/https://www.etheware.com/legal">Terms of Use</a></p>
                <div className="icon__div"/>
                <p><a href="/https://www.etheware.com/legal">Sales and Refunds</a></p>
                <div className="icon__div"/>
                <p><a href="/https://www.etheware.com/legal">Legal</a></p>
                <div className="icon__div"/>
                <p><a href="/https://www.etheware.com/sitemap">Site Map</a></p>
            </div>
                <div className="footer-paragraph">
                </div>
                <div className="footer-links_logo">
                <p>Copyright © Etheware LLC.<br /> All Rights Reserved.</p>
            </div>
        </div>
  )
}

export default Footer